import React, { ReactNode } from 'react'
import Badge from '../assets/images/most-popular-badge.svg'
import { BillingCycle, PricingCurrency, PricingTier } from '../pages/pricing'
import { SpaceScaleValue } from '../styles/stylePropTypes'
import CheckMarkIcon from './CheckMarkIcon'
import Box from './primitives/Box'
import Row from './primitives/Row'
import Stack from './primitives/Stack'
import Button from './system/Button'

const PricingCard = ({
  title,
  description,
  priceAnnually,
  priceMonthly,
  priceDescriptionAnnually,
  priceDescriptionMonthly,
  perksDescription,
  perks,
  checkboxVariant,
  callToAction,
  isHighlighted,
  currency,
  freeBadge,
  billingCycle,
}: PricingTier & {
  currency?: PricingCurrency
  billingCycle: BillingCycle
}) => {
  const price =
    billingCycle === BillingCycle.Annually ? priceAnnually : priceMonthly
  const priceDescription =
    billingCycle === BillingCycle.Annually
      ? priceDescriptionAnnually
      : priceDescriptionMonthly
  return (
    <Stack space={0} position="relative" alignItems="center" width="100%">
      {freeBadge ? <FreeBadge /> : null}
      <Stack
        height="100%"
        width="100%"
        position="relative"
        paddingX={24}
        paddingTop={42 as SpaceScaleValue}
        paddingBottom={{ xs: 32, md: 48 }}
        borderRadius={16}
        color={isHighlighted ? 'gray100' : 'black500'}
        textAlign={{ xs: 'center', md: 'left' }}
        borderColor={isHighlighted ? 'purple' : 'gray200'}
        css={`
          background: ${isHighlighted
            ? 'linear-gradient(180deg, #6A57FF 0%, #4736D3 100%)'
            : 'linear-gradient(180deg, #F7F8FB 0%, #F0F2F6 100%)'};
        `}
      >
        <Box.h2 font="h6" color={isHighlighted ? 'white' : 'black'}>
          {title}
        </Box.h2>
        <Price
          price={price}
          currency={currency}
          isHighlighted={isHighlighted}
        />
        <Box
          font="medium"
          marginTop={{ xs: 16, md: 8 }}
          minHeight={{ xs: 0, md: 76 }}
          fontSize={{ xs: 16, pricingWide: 18 }}
        >
          {priceDescription}
        </Box>
        <Button
          href={callToAction.url}
          variant={callToAction.buttonVariant}
          size="large"
          width="100%"
          maxWidth={{ xs: 420, md: undefined }}
          marginTop={{ xs: 24, md: 8 }}
          marginX="auto"
          fontSize={{ xs: 20, md: 18, pricingWide: 20 }}
        >
          {callToAction.label}
        </Button>
        <Box
          marginTop={32}
          minHeight={{ xs: 0, md: 76 }}
          font="h6"
          lineHeight="140%"
          color={isHighlighted ? 'gray100' : 'blockDark'}
          fontSize={{ xs: 18, md: 16, pricingWide: 18 }}
        >
          {description}
        </Box>
        <Stack
          space={16}
          marginTop={{ xs: 32, md: 8 }}
          marginBottom={24}
          maxWidth={400}
          marginX="auto"
          color={isHighlighted ? 'white' : 'black'}
        >
          {perksDescription ? <Perk>{perksDescription}</Perk> : null}
          {perks.map(perk => {
            return (
              <Perk key={perk}>
                <CheckMarkIcon
                  variant={checkboxVariant}
                  alignSelf="flex-start"
                />
                <Box marginLeft={16}>{perk}</Box>
              </Perk>
            )
          })}
        </Stack>
      </Stack>
    </Stack>
  )
}

const Price = ({
  price,
  currency,
  isHighlighted,
}: {
  price: string | number
  currency?: PricingCurrency
  isHighlighted: boolean | undefined
}) => {
  const hasPrice = Number.isInteger(price)
  return (
    <Box
      marginTop={24}
      minHeight={{ xs: 0, md: 74 }}
      color={isHighlighted ? 'white' : 'black'}
    >
      {hasPrice && currency === 'USD' && <CurrencySign>$</CurrencySign>}
      <Box.span
        fontFamily="FactorA"
        fontWeight={700}
        css={`
          font-size: ${hasPrice ? '64px' : '32px'};
          line-height: 1;
        `}
      >
        {price}
      </Box.span>
      {hasPrice && currency === 'EUR' && <CurrencySign>€</CurrencySign>}
    </Box>
  )
}

function CurrencySign(props: { children: ReactNode }) {
  return (
    <Box.span font="h2" display="inline-block" position="relative" top={-20}>
      {props.children}
    </Box.span>
  )
}

function Perk(props: { children: ReactNode }) {
  return (
    <Row font="textLabel" textAlign="left" fontWeight={400} fontSize={16}>
      {props.children}
    </Row>
  )
}

function FreeBadge() {
  // Note: Wrapped img in box because Box.img does not support responsive sizing
  return (
    <Box
      position="absolute"
      right={{ xs: -37, md: -38, pricingWide: -37 }}
      top={{ xs: -36, md: -30, pricingWide: -36 }}
      width={{ xs: 184, md: 165, pricingWide: 184 }}
      height={{ xs: 193, md: 157, pricingWide: 193 }}
      zIndex={1}
    >
      <img src={Badge} width="100%" height="100%" />
    </Box>
  )
}

export default PricingCard
