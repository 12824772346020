import React from 'react'
import theme from '../styles/theme'
import Box from './primitives/Box'

// Adapted from http://react-component.github.io/switch/demo/simple

const baseStyle = `
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 44px;
  height: 22px;
  line-height: 20px;
  padding: 0;
  vertical-align: middle;
  border-radius: 20px 20px;
  border: 1px solid ${theme.colors.gray200};
  background-color: ${theme.colors.gray50};
  cursor: pointer;
  transition: all .3s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: hidden;

  &:after{
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: ${theme.colors.white};;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left .3s cubic-bezier(0.35, 0, 0.25, 1);
  }

  &:focus {
    box-shadow: 0 0 0 2px tint(#2db7f5, 80%);
    outline: none;
  }
`

const checkedStyle = `
  border: 1px solid ${theme.colors.purple};
  background-color: ${theme.colors.purple};
  &:after{
    left: 22px;
    background-color: ${theme.colors.white};
  }
  &:hover {
    background-color: ${theme.colors.purple};
  }
`

interface Props {
  checked: boolean
  onChange?: (checked: boolean) => void
}

function Switch(props: Props) {
  return (
    <Box.button
      onClick={() => props.onChange?.(!props.checked)}
      role="switch"
      aria-checked={props.checked}
      css={getStyle(props.checked)}
    />
  )
}

function getStyle(checked: boolean) {
  return checked ? `${baseStyle} ${checkedStyle}` : baseStyle
}

export default Switch
