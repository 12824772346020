import React from 'react'
import MetaImage from '../assets/images/pricing-meta.png'
import BestDealBadge from '../components/BestDealBadge'
import { CheckMarkVariant } from '../components/CheckMarkIcon'
import G2AndCustomerLogos from '../components/G2AndCustomerLogos'
import Layout from '../components/Layout'
import PricingCalculator from '../components/PricingCalculator'
import PricingCard from '../components/PricingCard'
import PricingComparisonTable from '../components/PricingComparisonTable'
import FAQ from '../components/PricingFAQ'
import Switch from '../components/Switch'
import CTABlock from '../components/blocks/CTABlock'
import { HeroBlockPlain } from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Br from '../components/system/Br'
import Button, { Variant } from '../components/system/Button'
import { useCurrency } from '../regionUtils'
import { responsiveScale } from '../styles/helpers'

type FeatureConfig = {
  key: string
  label: string
  category: 'info' | 'features' | 'integrations'
  tooltip?: string
  url?: string
}

export const FREE_PLAN_MAX_DEVS = 9

export const pricingFeaturesConfig = [
  // Info
  {
    key: 'developers',
    label: 'Developers',
    category: 'info',
    tooltip:
      'The number of people who have committed code to connected repositories in the past 30 days.',
  },
  {
    key: 'customerSupport',
    label: 'Support channels',
    category: 'info',
    tooltip: 'Available customer service channels.',
  },
  {
    key: 'customerSuccess',
    label: 'Customer success manager',
    category: 'info',
    tooltip:
      'Lite customers with 100+ developers get a dedicated customer success manager.',
  },

  // Features
  {
    key: 'dora',
    label: 'DORA metrics',
    category: 'features',
    tooltip:
      'Measure change lead time, deployment frequency, change failure rate, and mean time to recovery.',
    url: '/dora-metrics/',
  },
  {
    key: 'prAnalytics',
    label: 'Pull request insights',
    category: 'features',
    tooltip:
      'Continuously improve your team’s PR metrics based on real-time data.',
    url: '/github-reporting/',
  },
  {
    key: 'ciInsights',
    label: 'CI insights',
    category: 'features',
    tooltip: 'Reduce CI wait times and costs with detailed CI/CD insights.',
    url: '/product/ci-cd-insights/',
  },
  {
    key: 'surveys',
    label: 'Surveys',
    category: 'features',
    tooltip:
      'Complement engineering metrics with feedback from your developers.',
    url: '/product/surveys/',
  },
  {
    key: 'slackFeedback',
    label: 'Slack feedback loops',
    category: 'features',
    tooltip:
      'Speed up development workflows with a two-way integration between GitHub and Slack.',
    url: '/github-slack-integration/',
  },
  {
    key: 'workingAgreements',
    label: 'Working agreements',
    category: 'features',
    tooltip: 'Set numeric targets and adopt healthy habits as a team.',
    url: '/product/working-agreements/',
  },
  {
    key: 'investmentBalance',
    label: 'Investment balance',
    category: 'features',
    tooltip:
      'Understand where engineering time goes and intentionally manage the balance between new feature work, maintenance, and more.',
    url: '/product/investment-balance/',
  },
  {
    key: 'workLog',
    label: 'Work log',
    category: 'features',
    tooltip:
      'Visualize engineering work, identify teamwork anti-patterns, and unblock the flow.',
    url: '/product/work-log/',
  },
  {
    key: 'initiatives',
    label: 'Initiatives',
    category: 'features',
    tooltip:
      'Stay on top of strategic cross-team initiatives and predictably deliver on agreed business outcomes.',
    url: '/product/initiatives/',
  },
  {
    key: 'capex',
    label: 'Software capitalization',
    category: 'features',
    tooltip:
      'Build flexible software cost capitalization reports by blending data from Swarmia with team and salary information.',
    url: '/product/software-capitalization/',
  },

  // Integrations
  { key: 'github', label: 'GitHub', category: 'integrations' },
  {
    key: 'githubEnterpriseCloud',
    label: 'GitHub Enterprise Cloud',
    category: 'integrations',
  },
  { key: 'githubActions', label: 'GitHub Actions', category: 'integrations' },
  { key: 'githubLogin', label: 'GitHub login', category: 'integrations' },
  { key: 'slack', label: 'Slack', category: 'integrations' },
  { key: 'jira', label: 'Jira', category: 'integrations' },
  { key: 'linear', label: 'Linear', category: 'integrations' },
  { key: 'jiraOnPrem', label: 'Jira Server', category: 'integrations' },
  {
    key: 'jiraDataCenter',
    label: 'Jira Software Data Center',
    category: 'integrations',
  },
  {
    key: 'githubEnterpriseServer',
    label: 'GitHub Enterprise Server',
    category: 'integrations',
  },
  { key: 'oktaSSO', label: 'Okta Single Sign-On', category: 'integrations' },
] as const satisfies readonly FeatureConfig[]

export type FeatureCategory = (typeof pricingFeaturesConfig)[number]['category']
export type FeatureKey = (typeof pricingFeaturesConfig)[number]['key']
export type FeatureValue = boolean | string // true/false/explanation

export type ComparisonData = {
  priceLabel?: string
  features?: Partial<Record<FeatureKey, FeatureValue>>
}
export interface PricingTier {
  title: string
  description: string
  priceAnnually: string | number
  priceMonthly: string | number
  priceDescriptionMonthly: string
  priceDescriptionAnnually: string
  perksDescription?: string
  perks: string[]
  checkboxVariant: CheckMarkVariant
  callToAction: { label: string; url: string; buttonVariant: Variant }
  freeBadge?: boolean
  isHighlighted?: boolean
  comparisonData: ComparisonData
}

const pricingTiers: PricingTier[] = [
  {
    title: 'Free',
    description: 'Great for small startups',
    priceAnnually: 0,
    priceMonthly: 0,
    priceDescriptionMonthly: 'for companies with up to 9 developers',
    priceDescriptionAnnually: 'for companies with up to 9 developers',
    perks: [
      'Insights from multiple data sources',
      'Working agreements',
      'Slack feedback loops',
      'Upgrade at any time',
    ],
    checkboxVariant: 'green',
    callToAction: {
      label: 'Sign up for free',
      url: 'https://app.swarmia.com/signup/',
      buttonVariant: 'primary',
    },
    comparisonData: {
      features: {
        // info
        developers: 'Up to 9',
        customerSupport: 'Email',

        // features
        dora: true,
        prAnalytics: true,
        ciInsights: true,
        surveys: true,
        slackFeedback: true,
        workingAgreements: true,
        investmentBalance: true,
        workLog: true,
        initiatives: true,

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        githubActions: true,
        githubLogin: true,
        slack: true,
        jira: true,
        linear: true,
      },
    },
  },
  {
    title: 'Lite',
    description: 'Improve pull request flow in teams',
    priceAnnually: 20,
    priceMonthly: 25,
    priceDescriptionMonthly: 'per developer per month',
    priceDescriptionAnnually: 'per developer per month, billed annually',
    perks: [
      'DORA metrics',
      'Pull request & CI insights',
      'Working agreements for code delivery',
      'Slack feedback loops for individuals and teams',
    ],
    checkboxVariant: 'green',
    callToAction: {
      label: 'Start free trial',
      url: 'https://app.swarmia.com/signup/',
      buttonVariant: 'primary',
    },
    comparisonData: {
      features: {
        // info
        developers: '10+',
        customerSupport: 'Chat and email',
        customerSuccess: true,

        // features
        dora: true,
        prAnalytics: true,
        ciInsights: true,
        surveys: true,
        slackFeedback: 'Limited',
        workingAgreements: 'Limited',
        investmentBalance: 'Limited',
        workLog: 'Limited',

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        githubActions: true,
        githubLogin: true,
        slack: true,
      },
    },
  },
  {
    title: 'Standard',
    freeBadge: true,
    description: 'Drive org-wide engineering effectiveness',
    priceAnnually: 39,
    priceMonthly: 45,
    priceDescriptionMonthly: 'per developer per month',
    priceDescriptionAnnually: 'per developer per month, billed annually',
    perksDescription: 'Includes everything in Lite, plus…',
    perks: [
      'Rich insights from multiple data sources',
      'Initiatives & project delivery analytics',
      'Investment balance',
    ],
    checkboxVariant: 'white',
    callToAction: {
      label: 'Get a demo',
      url: '/demo/',
      buttonVariant: 'secondary',
    },
    isHighlighted: true,
    comparisonData: {
      features: {
        // info
        developers: '10+',
        customerSupport: 'Chat and email',
        customerSuccess: true,

        // features
        dora: true,
        prAnalytics: true,
        ciInsights: true,
        surveys: true,
        slackFeedback: true,
        workingAgreements: true,
        investmentBalance: true,
        workLog: true,
        initiatives: true,

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        githubActions: true,
        githubLogin: true,
        slack: true,
        jira: true,
        linear: true,
      },
    },
  },
  {
    title: 'Enterprise',
    description: 'Scale with confidence',
    priceMonthly: 'Contact us',
    priceAnnually: 'Contact us',
    priceDescriptionMonthly: 'Request a quote',
    priceDescriptionAnnually: 'Request a quote',
    perksDescription: 'Includes everything in Standard, plus…',
    perks: [
      'Software capitalization',
      'Single Sign-On (SSO) with Okta',
      'On-premise integrations',
    ],
    checkboxVariant: 'black',
    callToAction: {
      label: 'Contact sales',
      url: '/demo/',
      buttonVariant: 'dark',
    },
    comparisonData: {
      priceLabel: 'Request a quote',
      features: {
        // info
        developers: 'Unlimited',
        customerSupport: 'Chat and email',
        customerSuccess: true,

        // features
        dora: true,
        prAnalytics: true,
        ciInsights: true,
        surveys: true,
        slackFeedback: true,
        workingAgreements: true,
        investmentBalance: true,
        workLog: true,
        initiatives: true,
        capex: true,

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        githubActions: true,
        githubLogin: true,
        slack: true,
        jira: true,
        linear: true,
        jiraOnPrem: true,
        jiraDataCenter: true,
        githubEnterpriseServer: true,
        oktaSSO: true,
      },
    },
  },
]

export type PricingCurrency = 'EUR' | 'USD'

export enum BillingCycle {
  Annually = 'Annually',
  Monthly = 'Monthly',
}

export const PricingPageInternal = ({
  currency,
}: {
  currency: PricingCurrency
}) => {
  const [billingCycle, setBillingCycle] = React.useState<BillingCycle>(
    BillingCycle.Annually,
  )

  return (
    <Layout
      title="Pricing"
      variant="light"
      isNew
      description="Explore Swarmia pricing options and find a plan that works for your software engineering organization. Free plan for startups available."
      metaImage={MetaImage}
    >
      <HeroBlockPlain
        title="Pricing & plans"
        customCta={<></> /* empty custom CTA hides the buttons */}
      />
      <Box paddingBottom={64}>
        <HeaderBillingCycleSelector
          billingCycle={billingCycle}
          setBillingCycle={setBillingCycle}
        />
      </Box>
      <Stack maxWidth={1400} marginX="auto">
        <Box
          display="flex"
          gap={{ xs: 16, lg: 24 }}
          marginX={24}
          marginBottom={24}
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'center', md: 'normal' }}
          justifyContent={{ xs: 'normal', md: 'center' }}
          paddingTop={16}
        >
          {pricingTiers.map(tier => (
            <PricingCard
              key={tier.title}
              {...tier}
              currency={currency}
              billingCycle={billingCycle}
            />
          ))}
        </Box>
        <Box>
          <Box
            marginTop={64}
            marginLeft={{ xs: 16, sm: 48 }}
            marginRight={{ xs: 16, sm: 48 }}
          >
            <PricingCalculator
              tiers={pricingTiers}
              currency={currency}
              billingCycle={billingCycle}
              setBillingCycle={setBillingCycle}
            />
          </Box>
          <Box marginY={64} marginX={{ xs: 0, sm: 48 }}>
            <PricingComparisonTable
              tiers={pricingTiers}
              currency={currency}
              billingCycle={billingCycle}
            />
          </Box>
        </Box>
      </Stack>
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <LeadBlock
        heading={
          <>
            Trusted by effective <Br />
            engineering organizations
          </>
        }
        link={{ title: 'Read customer stories', href: '/customers/' }}
      />
      <Box paddingTop={responsiveScale(32)} paddingBottom={responsiveScale(92)}>
        <G2AndCustomerLogos hideTitle />
      </Box>
      <CTABlock />
    </Layout>
  )
}

const HeaderBillingCycleSelector = (props: {
  billingCycle: BillingCycle
  setBillingCycle: (billingCycle: BillingCycle) => void
}) => {
  const isAnnual = props.billingCycle === BillingCycle.Annually
  return (
    <Row
      space={12}
      justifyContent="center"
      alignItems="center"
      fontSize={18}
      lineHeight="120%"
    >
      <Button
        variant="plain"
        font="normalLabel"
        color={isAnnual ? 'black400' : 'black900'}
        onClick={() => props.setBillingCycle(BillingCycle.Monthly)}
      >
        Billed monthly
      </Button>
      <Switch
        checked={isAnnual}
        onChange={() =>
          props.setBillingCycle(
            isAnnual ? BillingCycle.Monthly : BillingCycle.Annually,
          )
        }
      />
      <Button
        variant="plain"
        font="normalLabel"
        color={isAnnual ? 'black900' : 'black400'}
        onClick={() => props.setBillingCycle(BillingCycle.Annually)}
      >
        Billed annually
      </Button>
      <BestDealBadge active={isAnnual} />
    </Row>
  )
}

const PricingPage = () => {
  const currency = useCurrency()
  return currency !== undefined ? (
    <PricingPageInternal currency={currency ?? 'USD'} />
  ) : null
}

export default PricingPage
