import React from 'react'
import Box, { BoxStyleProps } from './primitives/Box'

export type CheckMarkVariant = 'green' | 'white' | 'black'
type Props = { variant: CheckMarkVariant } & Pick<BoxStyleProps, 'alignSelf'>

function CheckMarkIcon(props: Props) {
  const fills = {
    green: '#34AE73',
    white: '#fff',
    black: '#000',
  }
  return (
    <Box width={24} height={24} alignSelf={props.alignSelf ?? 'center'}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="checkmark-path-mask" x="0" y="0" width="24" height="24">
          <rect x="1.5" y="1" width="22" height="22" rx="11" fill="white" />
          <path
            d="M7.94656 12.2975L11.4868 16.0034L17.3366 7.44798"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <rect
          x="1.5"
          y="1"
          width="22"
          height="22"
          rx="11"
          fill={fills[props.variant]}
          mask="url(#checkmark-path-mask)"
        />
      </svg>
    </Box>
  )
}

export default CheckMarkIcon
