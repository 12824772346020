import React from 'react'
import Box from './primitives/Box'

const BestDealBadge = (props: { active: boolean }) => (
  <Box
    fontSize={16}
    backgroundColor="green100"
    color="green600"
    opacity={props.active ? 1 : 0.5}
    paddingX={8}
    paddingY={4}
    borderRadius={6}
    css={`
      line-height: inherit;
    `}
  >
    Best deal
  </Box>
)

export default BestDealBadge
